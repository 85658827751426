<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务中心</a-breadcrumb-item>
            <a-breadcrumb-item>作业点评</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box review" :style="{ height:wHeight}">
            <a-drawer
                placement="left"
                :closable="false"
                :visible="true"
                :mask="false"
                :get-container="false"
                :wrap-style="{ position: 'absolute',left:'-15px'}"
                >
                    <div>
                        <a-form layout='inline'>
                            <a-form-item>
                                <a-tooltip placement="topLeft" >
                                <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 200px">
                                    <a-select-option :value="0">我的</a-select-option>
                                    <a-select-option :value="1">我下属的</a-select-option>
                                </a-select>
                                </a-tooltip>
                            </a-form-item>
                        </a-form>
                    </div>
            </a-drawer>
            <!-- <a-drawer
                placement="left"
                :closable="false"
                :visible="true"
                :mask="false"
                :get-container="false"
                :wrap-style="{ position: 'absolute',left:'235px'}"
                >
                    <div>
                        <a-form layout='inline'>
                            <a-form-item>
                                <a-tooltip placement="topLeft" >
                                <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 200px">
                                    <a-select-option :value="0">我的</a-select-option>
                                    <a-select-option :value="1">我下属的</a-select-option>
                                </a-select>
                                </a-tooltip>
                            </a-form-item>
                        </a-form>
                    </div>
            </a-drawer> -->
            <div class="clearfix table-tools">
                <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item>
                    <a-button type="primary" icon="plus" @click="showEditModal()">新增作业</a-button>
                    </a-form-item>
                </a-form>
                </div>
            </div>
            <div style="display:flex">
                <div style="width:256px"></div>
                <div class="review-wrap" 
                :style="{ height:wHeight}"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10"
                >
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <a-card v-for='(item,j) in list' :key="item.homework_id" hoverable class="review-card">
                        <h3>{{item.homework_title}}</h3>
                        <div v-html="item.homework.homework_desc"></div>
                        <div style="margin:10px 0">
                            <span v-for='(it,index) in item.homework.homework_images' :key="index" style="display:inline-block;margin:5px">
                                <LImg :preview="j" style="width:75px;height:75px" :src='it'/>
                            </span>
                        </div>
                        <div style="margin:10px 0">
                            <audio v-for='(it,index) in item.homework.homework_audios' :key="index" :src="it.audio_path" controls />
                        </div>
                        <div class="review-video">
                            <div @click="showVideoModal(it)" v-for='(it,index) in item.homework.homework_videos' :key="index" class="review-video-item">
                                <video style="background:#000;border-radius:2px" :src="it"  width="75" height="75">
                                    Your browser does not support the video tag.
                                </video>
                                <div class="review-video-icon">
                                    <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <span>{{item.classes_name}}</span>
                            <a-divider type="vertical" />
                            <span>{{item.homework_employee.employee_name}}</span>
                            <a-divider type="vertical" />
                            <span>{{item.homework_date}}</span>
                        </div>
                        <div class='review-statistics'>
                            <div @click="showStatistics(item,'1')" class="review-statistics-item">
                                <a-icon :style="{ fontSize: '16px'}" type="eye" />
                                <span>{{item.homework_read_count}}/{{item.assign_count}}</span>
                            </div>
                            <div @click="showStatistics(item,'3')" class="review-statistics-item">
                                <a-icon :style="{ fontSize: '16px'}" type="message" />
                                <span>{{item.homework_comment_count}}/{{item.assign_count}}</span>
                            </div>
                            <div @click="showStatistics(item,'2')" class="review-statistics-item">
                                <a-icon :style="{ fontSize: '16px'}" type="check-circle" />
                                <span>{{item.finish_count}}/{{item.assign_count}}</span>
                            </div>
                        </div>
                        <div class="review-ellipsis">
                            <a-dropdown>
                                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                    <a-icon :style="{ fontSize: '24px'}" type="ellipsis" />
                                </a>
                                <a-menu slot="overlay">
                                    <a-menu-item @click="showEditModal(item)">
                                        <a href="javascript:;">编辑</a>
                                    </a-menu-item>
                                    <a-menu-item @click="deleteHomework(item,j)">
                                        <a href="javascript:;">删除</a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </a-card>
                    <div style="height:100px;text-align:center">
                        <span v-if="busy">没有更多了</span>
                    </div>
                </div>
            </div>
        </div>
        <editModal v-if="visible" :item="modalData"/>
        <videoModal v-if="isVideoModal" :video='video'/>
        <statistics v-if="isStatistics" :item="modalData"/>
        <!-- <imageEdit v-if="isImageEdit" :imgsrc="imgsrc" :item="modalData"/> -->
    </div>
</template>

<script>
    import videoModal from './videoModal'
    import imageEdit from './imageEdit'
    import statistics from '../zydp/statistics/index'
    import editModal from '@/views/market/xuetang/zydp/editModal'
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'review',
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                list: [],
                busy: false,
                loading: false,
                visible: false,
                isImageEdit: false,
                isVideoModal: false,
                isStatistics: false,
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                current: 1,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 0,
                    "per-page": 10,
                    "search": {
                        is_sub:0,
                    },
                    "sort": ''
                },
                video:'',
                imgsrc:'',
                myHeight:0,
            }
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 165}px`
            }
        },
        directives: { infiniteScroll },
        components: {
            imageEdit,
            editModal,
            videoModal,
            statistics,
        },
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.myHeight = this.windowHeight()
            this.getList()
        },
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                let res = await this.$store.dispatch('scheduleHomeworkAction', this.searchParams)
                if(res.items.length >0){
                    this.list = [...this.list,...res.items]
                }else{
                    this.busy = true
                }
                this.pageParams = res._meta
                this.loading = false
            },
            showVideoModal(src){
                this.video = src
                this.isVideoModal = true
            },
            hideVideoModal(){
                this.isVideoModal = false
            },
            showImageEdit(it,item){
                this.modalData = item
                this.imgsrc = it
                this.isImageEdit = true
            },
            hideImageEdit(){
                this.isImageEdit = false
            },
            showStatistics(item,num){
                this.modalData = item
                this.modalData.activeIndex = 3
                this.isStatistics = true
            },
            hideStatistics(type){
                this.isStatistics = false
            },
            showEditModal(it) {
                this.modalData = it
                if(it){
                    let homework_images = []
                    let homework_audios = []
                    let homework_videos = []
                    it.homework.homework_images.forEach((item,index)=>{
                        let signIndex = item.search(/cn/)
                        let sign = item.substring(signIndex + 3)
                        let obj = {
                            uid: `-${index+1}`,
                            name: `image${index+1}.png`,
                            status: 'done',
                            url:item,
                            type:'image/jpeg',
                            response:{
                                key:sign
                            }
                        }
                        homework_images.push(obj)
                    })
                    it.homework.homework_audios.forEach((item,index)=>{
                        let signIndex = item.audio_path.search(/cn/)
                        let sign = item.audio_path.substring(signIndex + 3)
                        let obj = {
                            uid: `-${index+1}`,
                            name: `audio${index+1}.mp3`,
                            status: 'done',
                            url:item,
                            type: 'audio/mpeg',
                            response:{
                                key:sign
                            }
                        }
                        homework_audios.push(obj)
                    })
                    it.homework.homework_videos.forEach((item,index)=>{
                        let signIndex = item.search(/cn/)
                        let sign = item.substring(signIndex + 3)
                        let obj = {
                            uid: `-${index+1}`,
                            name: `video${index+1}.mp4`,
                            status: 'done',
                            url:item,
                            type:'video/mp4',
                            response:{
                                key:sign
                            }
                        }
                        homework_videos.push(obj)
                    })
                    it.homework_images = homework_images
                    it.homework_audios = homework_audios
                    it.homework_videos = homework_videos
                }
                this.visible = true
            },
            hideModal(val) {
                if(val){
                    this.searchList()
                }
                this.visible = false
            },
            deleteHomework(item,index) {
                let that = this
                this.$confirm({
                    title: `确定要删除该作业?`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('homeworkDeleteAction', {data:{ assign_id: item.homework_id }})
                        .then(res=>{
                            that.list.splice(index,1)
                            that.$message.success('操作成功~')
                            resolve(res)
                        })
                        .catch(err=>{
                            that.$message.error('操作失败~')
                            resolve(err)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
                this.searchList()
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
            handleInfiniteOnLoad(){
                if(this.searchParams.page != 0){
                    if(!this.busy){
                        this.getList()
                    }
                }
                this.searchParams.page++
            },
            searchList(){
                this.searchParams.page = 1
                this.list = []
                this.busy = false
                this.getList()
            },
        },
    }
</script>

<style lang="scss" scoped>
    .review{
        position: relative;
        height: 650px;
        overflow: hidden;
        &-wrap{
            flex: 1;
            height: 650px;
            overflow-y: auto;
        }
        &-card{
            width: 600px;
            margin:0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            position: relative;
        }
        &-video{
            display: flex;
            align-items: center;
            margin: 10px 0;
        }
        &-video-item{
            position: relative;
        }
        &-video-icon{
            position: absolute;
            top: 23px;
            left: 23px;
            background: #000;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
        }
        &-statistics{
            display: flex;
            justify-content: flex-end;
        }
        &-statistics-item{
            display: flex;
            align-items: center;
            margin-left: 8px;
            cursor: pointer;
            span{
                margin-left:5px;
            }
        }
        &-ellipsis{
            position: absolute;
            top: -12px;
            right: -40px;

        }
    }
</style>